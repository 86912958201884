.best-rating {
	display: inline-block;
	padding: 5px;
	line-height: 0;
	text-decoration: none;
	color: $color-text !important;

	svg {
		display: inline;
		width: auto;
		height: 20px;
		&.icon-google { width: 20px; }
		&.icon-stars {
			width: 100px;
			fill: theme(review, base);
		}
	}

}


	.best-title {
		display: block;
		@include font-size(13);
		line-height: 1em;
		font-weight: $heading-weight-normal;
	}