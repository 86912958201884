.menu_top {
	line-height: 0;
	background-color: darken($color-secondary, 7.5%);
}

	.menu-top {

		@include make-flex;
		flex-wrap: nowrap;

		> .menu-item {
			@include make-flex;
			width: auto;
			
			&:not(:last-child) { border-right: 1px solid $white; }

			&:last-child {
				margin-left: auto;
				background-color: darken($color-facebook, 3%);
				.menu-link:hover {
					background-color: lighten($color-facebook, 8%);
				}
			}

			&.active > .menu-link {
				font-weight: $heading-weight-bolder;
				background-color: $white;
				color: $color-text;
			}

			> .menu-link {
				@include make-flex;
				align-items: center;
				width: 100%;
				justify-content: center;
			}

		}
	}

	.menu-top .menu-link {
		padding: 12px 15px 13px;
		@include font-size(15);
		font-weight: $body-weight-bold;
		line-height: 1em;
		color: $white;
		&:hover {
			background-color: theme(highlight, base);
		}
	}
