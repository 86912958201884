.sidebar {

	.form {
		margin-bottom: 30px;
		border-radius: 6px;
		border: 2px solid #ddd;
	}

	@include media-breakpoint-down(md) {
		padding-top: 30px;
	}

	.page_contact & {		
		@include media-breakpoint-down(md) {
			padding-top: 0;
			padding-bottom: 30px;
		}
	}

}