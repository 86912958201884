.front_services {

	@include media-breakpoint-up(md) {
		.services_item { width: percentage(1/2); }
	}

	@include media-breakpoint-only(lg) {
		.services_item { width: percentage(1/2); }
	}

}

.services_title {
	text-align: center;
	.services-title {
		font-weight: $body-weight-bolder;
	}
}

.services_item {

	padding: 10px;
	line-height: 1.4em;

}

	.service_wrapper {
		display: block;
		position: relative;
		padding: 15px;
		text-align: center;
		border-radius: 3px;
		border: 1px solid #ddd;
		background-color: $white;
		@include media-breakpoint-up(sm) {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			text-align: left;
			justify-content: space-between;
			.service_text { width: 65%; }
			.service_media {
				width: 30%;
				img { max-width: none; }
			}
		}
		@include media-breakpoint-only(md) {
			display: block;
			text-align: center;
			.service_text { width: 100%; }
			.service_media {
				width: 100%;
				img { max-width: 150px; }
			}
		}
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}
	}

	.service_text {

		h3 {
			margin: 0 0 10px;
			@include font-size(18);
			font-weight: $heading-weight-bold;
			color: $color-secondary;
		}

		a {
			@include transition(all 200ms);
			color: $color-text;
			vertical-align: middle;	
			&:hover {
				@include transition(all 200ms);
				color: $color-text;
				.icon {
					@include transition(all 200ms);
					margin-left: 5px;
				}
			}
		}

		.icon {
			@include transform(rotate(-90deg));
			@include transition(all 200ms);
			position: relative;
			top: -0.05em;
			width: 14px; height: 14px;
			vertical-align: middle;
			fill: $color-highlight;
		}
	}

	.service_media {
		img {
			max-width: 200px;
			width: 100%;
			margin: 0 auto;
			border-radius: 100%;
		}		
	}