.gallery {
    overflow: hidden;
    margin-bottom: 1em;
    img:not(:last-child) { margin-bottom: 15px; }
}
    // G3

    .g3-md {
        @include media-breakpoint-only(md) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

