.slider_reviews {

    padding: 0 36px;

    .slick-prev,
    .slick-next {
        z-index: 999999;
        height: 100%;
        width: 23px;
        background-color: transparent;
        background-image: url("/_/images/icons/slide-np-text.png") !important;
        background-size: 40px 20px;
        opacity: 0.3;
        &:hover {
            opacity: 0.7;
        }
    }	

    .slick-prev {
        left: 2px;
        background-position: 0 center;
    }

    .slick-next {
        right: 0;
        background-position: -20px center;
    }

}