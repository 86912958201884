.form_quick {

	padding: 20px;
	background-color: $white;

	@include media-breakpoint-only(md) {

		@include make-flex;
		justify-content: space-between;
		.form-title { width: 100%; }
		.form_wrap_info { width: 49%; }
		.form_wrap_comments { width: 49%; }
		.form_wrap_submit {
			@include make-flex;
			width: 100%;
			flex-wrap: nowrap;
			margin-top: 10px;
			.form_submit { margin-left: 270px; }
		}

	}

	/* .form_spam {
		height: 61px;
		.g-recaptcha {
			top: -8px;
			left: -32px;
			@include transform(scale(0.79));
		}
	} */

}