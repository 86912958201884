body {

	min-width: 360px;
	background-color: $body-bg;
	background-attachment: fixed;
	background-image: repeating-linear-gradient(
		-45deg,
		#f2f2f2,
		#f2f2f2 200px,
		transparent 200px,
		transparent 400px
	);

	@include media-breakpoint-up(lg) {
		background-image: repeating-linear-gradient(
			-45deg,
			#f2f2f2,
			#f2f2f2 300px,
			transparent 300px,
			transparent 600px
		);		
	}

	&:not(.page_front) {
			
		@include media-breakpoint-up(sm) {
			padding-bottom: 200px; height: 100%; 
			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}
		
		@include media-breakpoint-up(lg) {
			padding-bottom: 208px;
		}

	}

}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}