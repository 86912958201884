.block_announce {
	padding: 15px;
	@include font-size(18);
	font-weight: $body-weight-bolder;
	letter-spacing: -0.0425em;
	line-height: 1em;
	text-align: center;
	text-shadow: 0 1px 1px rgba(black,0.3);
	text-transform: uppercase;
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background-color: $color-highlight;
 	background: linear-gradient(
 		to right,
 		theme(highlight, dark),
 		theme(highlight, light) 40%,
 		theme(highlight, light) 60%,
 		theme(highlight, dark)
	);

	color: $white;
	.row { align-items: center; }

	@include media-breakpoint-up(md) {
		line-height: 1em;
		.wrap { width: percentage(1/3); }
	}

}