.block_main.content {
	background-color: rgba(white,0.5);
}

.page_front .block_main.content {
	background-color: transparent;
}

.main {

	@include media-breakpoint-up(lg) {
		.form-title { margin-top: 0; } 
	}


	>*:last-child { margin-bottom: 0; }

}
