.front_choose {
	text-shadow: 0 1px 1px rgba(black,0.3);
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background-color: $color-highlight;
 	background: linear-gradient(
 		to right,
 		theme(highlight, dark),
 		theme(highlight, light) 40%,
 		theme(highlight, light) 60%,
 		theme(highlight, dark)
	);
	color: $white;

	.row { justify-content: center; }
	
	.icon { fill: #f90; }

	@include media-breakpoint-up(md) {
		padding-bottom: 5px;
		.wrap {
			@include make-col(15);
			.choose-media { margin-top: -50px; }
			.choose-list > li { margin-bottom: 15px !important; }
		}
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 10px;
		.wrap { @include make-col(12); }
		.choose-media {
			width: 200px;
			height: 200px;
		}

	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(10); max-width: 740px !important; }
	}

}

.choose-title {
	&::before { content: "Why Choose "; }
	&::after { content: "?"; }
}

	.choose_media {		
		@include make-flex;
		flex-wrap: nowrap;
		.choose-list { margin-right: 15px; }
		.choose-media { margin-left: auto; }
	}

	.choose-media {
		width: 180px;
		height: 180px;
		@include media-breakpoint-up(sm) {}
	}

	.choose-list {
		li {
			@include font-size(18);
			font-weight: $body-weight-bold;
			letter-spacing: -0.0425em;
			vertical-align: middle;
			> * { vertical-align: middle; }			
		}

 		@include media-breakpoint-up(md) {
			max-width: 480px;
			li {
				float: left;
				&:nth-child(odd) { width: 42%; margin-right: 2%; }
				&:nth-child(even) { width: 56%; }
			}
		}

		@include media-breakpoint-up(lg) {
			//max-width: 440px;
		}

	}