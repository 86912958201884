.site_info {

	background-color: $color-primary;
 	background: linear-gradient(
 		to right,
 		theme(primary, dark),
 		theme(primary, base) 40%,
 		theme(primary, base) 60%,
 		theme(primary, dark)
	);

	text-shadow: 0 1px 1px rgba(black,0.3);
	color: $white;

	a { color: $white; }

	.phone {
		@include font-size(20);
		font-weight: $body-weight-bolder;
		color: $white;
	}

	.list > li:not(:last-child) { margin-bottom: 4px; }

	@include media-breakpoint-up(sm) {
		.row { align-items: center; }
		.wrap { @include make-col(8); }
		.info_copyright { text-align: right; }
		.list { margin-bottom: 0; }
	}
}
