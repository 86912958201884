.block {
	padding: 30px 15px;
}

	.wrap > *:first-child { margin-top: 0; }
	.wrap > *:last-child { margin-bottom: 0; }

	.block_title {
		margin-bottom: 15px;
		.block-title { margin: 0; }
	}

		.block-title {
			margin: 0 0 15px;
			@include font-size(20);
			@include media-breakpoint-up(lg) { @include font-size(22); }
		}

.front_emergency {
	//margin-bottom: 30px;
	padding: 15px;
	text-align: center;
	text-shadow: 0 1px 1px rgba(black,0.3);
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background-color: $color-danger;
 	background: 
 	linear-gradient( -43deg, transparent 50%, rgba(white, 0.13) 50%, transparent ),
 	linear-gradient(
 		to right,
 		theme(danger, dark),
 		theme(danger, light) 40%,
 		theme(danger, light) 60%,
 		theme(danger, dark)
	);
	color: $white;
	.emergency_wrapper span {
		strong,
		em { color: $white; }
	}
	@include media-breakpoint-up(md) {
		.wrap {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			.emergency_text_wrapper {
				margin-right: 30px;
				@include font-size(24);
				font-weight: $body-weight-bold;
			}
		}
	}
}

.emergency_wrapper {
	display: inline-block;
	text-align: center;
	line-height: 1em;
	span {
		display: flex;
		align-items: center;
		font-weight: $body-weight-bold;
		line-height: 1em;
		letter-spacing: -0.0375em;
		text-transform: uppercase;
		text-align: left;
		> strong,
		> em { display: inline-block; }
		strong {
			margin-right: 7px;
			@include font-size(43);
			line-height: 1em;
			font-weight: $body-weight-bolder;
			color: $color-danger;
		}
		em {
			@include font-size(20);
			line-height: 1em;
			font-style: normal;
			color: $color-primary;
		}
	}
	small {
		display: block;
		text-transform: uppercase;
		@include font-size(17);
		line-height: 1em;
		letter-spacing: 0.0475em;
	}
}

.front_reviews {
	padding: 45px 15px;
	text-align: center;
	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(13); }
	}
	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(12); }
	}
}