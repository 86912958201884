.front_jumbo {

	background-image: linear-gradient(
		to bottom,
		transparent 28%,
		$color-secondary 28%,
		$color-secondary 72%,
		transparent 72%
	);

	.row {
  		box-shadow: 0 40px 30px -40px rgba(0, 0, 0, 0.6);
	}

	@include media-breakpoint-up(lg) {
		.row {
			align-items: stretch;
			border: 1px solid #e5e5e5;
		}
		.wrap { padding: 0; }
		.jumbo_marketing {
			position: relative;
			@include make-col(11);
		}
		.jumbo_conversion {
			@include make-col(5);
			background-color: $white;
		}
	}

}

	.slider_jumbo {

		@include media-breakpoint-up(md) {

			.slide {
				background-size: cover;
				background-position: top center;
			}

			.slide-placeholder { opacity: 0; }

			.slide_1 { background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
			.slide_2 { background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
			.slide_3 { background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
			.slide_4 { background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
			.slide_5 { background-image: url("/_/images/slides/jumbo/5-lg.jpg"); }
			.slide_6 { background-image: url("/_/images/slides/jumbo/6-lg.jpg"); }

		}

	}