@mixin widget-color($color: #333) {
	border-color: $color;
	.widget-title { color: $color; }
	a:not(.menu-link, .btn) { color: $color !important; }
}

.widget {
	margin-bottom: 30px;
	padding: 15px;
	@include font-size(16);
	line-height: 1.275em;
	border-radius: 6px;
	border: 2px solid #ccc;
	background-color: $white;

	&.widget-primary { @include widget-color($color-primary); }
	&.widget-secondary { @include widget-color($color-secondary); }
	&.widget-highlight { @include widget-color($color-highlight); }
	&.widget-accent { @include widget-color($color-accent); }

	> *:first-child { margin-top: 0; }

	> *:last-child { margin-bottom: 0; }

}

	.widget-title {
		margin: 15px 0 10px;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0375em;
	}

.widget-emergency {
	padding: 0;
	border: none;
	background-color: transparent;
	text-align: center;
	line-height: 0em;

	.icon-stopwatch {
		fill: #bbb;
		width: 50px;
		height: 57px;
		line-height: 0em;
	}

	.widget_wrapper {
		@include make-flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		.emergency_media_wrapper { margin-right: 10px; }
	}

}