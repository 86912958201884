.gmb-button-review {
	display: flex;
	box-sizing: border-box;
	flex-wrap: 1;
	width: 240px;
	height: 61px;
	padding: 0 8px;
	@include font-size(14);
	line-height: 1em;
	text-decoration: none;
	border-radius: 6px;
	border: 0.075em solid #fff;
	background-image: linear-gradient(to right, $white 50%, #4285f4 50%);
	color: $white !important;
	svg { align-self: center; }
	div {
		display: flex;
		align-items: center;
		height: 59px;
		margin-left: 8px;
		padding: 10px;
		text-align: left;
		background-color: #4285F4;
	}
}

.review-icon {
	&.icon-g { width: 40px; height: 41px; }
	&.icon-bbb { width: 240px; height: 61px; }
}