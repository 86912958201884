.menu_main {
	line-height: 0;
}

	.menu-main > .menu-item {

		z-index: 7;
		position: relative;
		display: inline-block;
		padding: 0 2px;
		@include font-size(15);
		font-weight: $body-weight-bold;

		&.active > .menu-link {
			border: none !important;
			background-color: transparent !important;
			box-shadow: none;
			color: $color-secondary !important;
			@include transform(scale(1));
			b { font-weight: $body-weight-bolder; }
		}

		&.open > .menu-link {
			border-color: #ddd !important;
			background-color: $white !important;
			color: $color-primary;
			.caret { fill: $color-primary; }
		}

		> .menu-link {
			@include transform(scale(0.95));
			padding: 10px 15px;
			border-radius: 3px;
			border: 1px solid transparent;
			background-color: $color-primary;
			box-shadow: 0 15px 12px -15px rgba(0, 0, 0, 0.65);
			color: $white;
			.caret { fill: $white; }
			&:hover, &:focus {
				@include transform(scale(1));
				box-shadow: none;
				background-color: $color-secondary;
			}
		}

	}

	.menu-main .menu-link {
		padding: 10px;
		color: $color-text;
	}

