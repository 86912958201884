.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 0;
}

.logo {
	display: inline-block;
}

	.branding-logo {
		line-height: 1em;
		display: inline-block;
		letter-spacing: 0;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		> div { line-height: 0; }
		strong {
			@include font-size(21);
			font-weight: $body-weight-bolder;
			//letter-spacing: -0.025em;
			line-height: 1em;
			color: $color-primary;
		}
		small {
			display: block;
			@include font-size(14);
			font-weight: $body-weight-bold;
			letter-spacing: -0.025em;
			line-height: 1em;
			color: $color-highlight;
		}
		@include media-breakpoint-up(md) {
			.logo_wrapper {
				@include make-flex;
				flex-wrap: nowrap;
				align-items: center;
				text-align: left;
				.banner-logo {
					max-width: 80px;
					margin: 0 10px 0 0;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			.banner-logo { max-width: 100px; }
			strong { @include font-size(23); }
			small { @include font-size(16); }
		}
	}

	.banner-logo {
		display: block;
		width: 100%;
		max-width: 120px;
		margin: 0 auto 5px;
	}

	.site-info-logo {}