.front_about {

	.img-about {
		width: 100%;
		max-width: 50%;
		float: right;
		margin: 0 0 15px 15px;
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(6); }
	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(5); }
	}

}