$nav-mobile-bg: 			theme(secondary, base) !default;
$mobile-link-bg: 			transparent !default;
$mobile-link-color:			$white !default;
$mobile-hover-bg: 			theme(primary, base) !default;
$mobile-hover-color: 		$white !default;
$mobile-active-bg: 			$white !default;
$mobile-active-color: 		theme(primary, base) !default;

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}


.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 300px;
	height: 100%;
	padding: 25px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		margin-top: 0;
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.menu.mobile-menu {
		> li:nth-child(3) { border-bottom: none; }
		> li:last-child { display: none; }
	}

	.menu { margin-bottom: 25px; }

	.menu > .menu-item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,1); }
	.menu > .menu-item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.2); }

	.menu > .menu-item {

		&.active .menu-link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			> .menu-link {
				background: $white !important;
				color: $color-primary !important;
				.caret { fill: $color-primary !important; }
			}
			.dropdown-menu { display: block; }
		}

	}	

		.menu > .menu-item > .menu-link {
			padding: 12px;
			font-weight: 600;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
			.caret { fill: $white; }
		}

	.dropdown-menu {
		display: none;
		margin-top: 1px !important;
		padding: 10px;
		min-width: 0;
		background-color: $white;

		.menu-item:not(:last-child) { border-bottom: 1px dotted #bbb; }

		.menu-item.active .menu-link {
			color: $color-primary;
			.caret-push { fill: $color-primary; }
		}

		.menu-link {
			padding: 12px;
			font-weight: 600;
			background-color: transparent;
			color: $color-text;
			.caret-push { fill: $color-secondary; }
			&:hover, &:focus {
				outline: none;
				color: $color-secondary;
			}				
		}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

