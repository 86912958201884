.block_banner {
	text-align: center;
	border-top: 10px solid $color-primary;

	@include media-breakpoint-only(md) {
		.banner_contact { margin-top: 20px; }
	}

	@include media-breakpoint-up(md) {
		padding: 0 0 20px;
		.row { align-items: center; }
		.banner_branding { @include make-col(10); }
		.banner_contact { @include make-col(6); }
		.banner_menu { margin-top: 20px; }
	}

	@include media-breakpoint-up(lg) {
		padding-top: 20px;
	}

}

	.banner_button {
		text-align: left;
	}

.banner_branding {
	line-height: 0;
	
	.branding-logo { margin: -40px 0 5px; }

	@include media-breakpoint-up(md) {
		text-align: left;
		.branding-logo { margin: 0; }
	}

	@include media-breakpoint-only(md) {
		.branding-logo { margin: 20px 0 0 !important; }
		.best-rating {
			margin: 0 0 0 90px;
			.best-flex {
				margin-top: -20px;
				@include make-flex;
				align-items: center;
			}
			.best-title {
				display: inline-block;
				margin-left: 5px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		br { display: none; }
		.branding-title {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
		}
		.best-rating { margin-left: 15px; }
	}

}


.banner_contact {
	margin-top: 15px;
	@include media-breakpoint-up(md) {
		margin-top: 0;
 			text-align: right;
	}
}

	.banner_menu {
		padding: 0;
		@include media-breakpoint-up(md) {
			.menu-main {
				@include make-flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				> .menu-item {
					@include make-flex;
					width: percentage(1/7);
					> .menu-link {
						@include make-flex;
						align-items: center;
						width: 100%;
						justify-content: center;
					}
				}
			}
		}
		@include media-breakpoint-up(lg) {
			//.menu-main > .menu-item > .menu-link { @include font-size(16); }
		}
		@include media-breakpoint-up(xl) {
			//.menu-main > .menu-item > .menu-link { padding: 18px 10px; }
		}
	}