.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

    .slick-prev,
    .slick-next {
        opacity: 0;
    }

    .slide-placeholder { width: 100%; }

    .slide { position: relative; }

    .slide_caption {
        position: absolute;
        left: 0; right: 0; bottom: 0;
        width: 100%;
        padding: 10px 20px;
        @include font-size(16);
        font-weight: $heading-weight-bolder;
        line-height: 1em;
        text-align: center;
        text-shadow: 0 1px 2px rgba(black, 0.5);
        background-color: rgba(theme(primary, dark), 0.8);
        color: $white;
    }

    @include media-breakpoint-up(lg) {
        .slide_caption {
            @include font-size(20);
        }
    }

}