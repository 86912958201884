.page_title {
	padding: 45px 0 0;
	border-bottom: 5px solid $white;
	color: $white;

	background-color: $color-secondary;
 	background: linear-gradient(
 		to right,
 		theme(secondary, dark),
 		theme(secondary, base) 40%,
 		theme(secondary, base) 60%,
 		theme(secondary, dark)
	);
	
	.page_title_wrapper {
		padding: 10px;
		background-color: rgba(black, 0.4);
	}

	@include media-breakpoint-up(md) { padding-top: 90px; }
	@include media-breakpoint-up(lg) { padding-top: 150px; }

}

	.page-title {
		margin: 0;
		small {
			font-size: 65%;
			font-style: italic;
			font-weight: $body-weight-normal;
			letter-spacing: 0.01em;
			line-height: 1em;
			color: #fff;
		}
	}
